import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { useFormikContext } from 'formik';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from './Stack';
import { useSendEvent } from '../hooks/useEmbedEvents';

export interface Props extends Pick<Sesame, 'open' | 'onClose'> {
    filtersActive: boolean;
    removeActiveFilters: () => void;
    /**
     * Keys to check whether the filters are applied or not. Looks at initial values and compares them to the current values
     */
    dirtyCheckKeys: string[];
}

const SearchFiltersModal: FC<React.PropsWithChildren<Props>> = ({
    onClose,
    open,
    filtersActive,
    removeActiveFilters,
    dirtyCheckKeys: filterKeys,
    children,
}) => {
    const { submitForm, values, initialValues } = useFormikContext();
    const formFiltersActive = useMemo(
        () => !isEqual(pick(initialValues, filterKeys), pick(values, filterKeys)),
        [filterKeys, initialValues, values]
    );
    const sendEvent = useSendEvent();
    useEffect(() => {
        sendEvent({ type: 'iframeFullScreenToggle', fullscreen: open, source: 'travelbase' });
    }, [open, sendEvent]);

    return (
        <StyledModal onClose={onClose} open={open}>
            <ModalHeader style={{ textTransform: 'capitalize' }}>
                <FormattedMessage defaultMessage="filters" />
            </ModalHeader>
            <Stack mt={4} spacing={4}>
                {children}
                <Actions>
                    <Button
                        style={{ width: '100%' }}
                        onClick={() => {
                            onClose();
                            // As the modal is rendered through a portal, the form won't be automatically submitted
                            // so we need to do it manually.
                            submitForm();
                        }}
                    >
                        <FormattedMessage defaultMessage="Filters toepassen" />
                    </Button>
                    <Button
                        variant="outline"
                        disabled={!formFiltersActive && !filtersActive}
                        onClick={() => {
                            removeActiveFilters();
                        }}
                        style={{ width: '100%' }}
                        type="button"
                    >
                        <FormattedMessage defaultMessage="Filters verwijderen" />
                    </Button>
                </Actions>
            </Stack>
        </StyledModal>
    );
};

export default SearchFiltersModal;

const StyledModal = styled(Modal)`
    > [data-reach-dialog-content] {
        height: auto;
    }
`;

const Actions = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    button + button {
        margin-top: ${({ theme }) => theme.spacing['30_Small']};
    }
    width: 100%;
`;
